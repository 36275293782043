export const COLORS = {
  "very-negative": "rgba(255, 102, 102, 0.7)",
  negative: "rgba(255, 102, 102, 0.5)",
  neutral: "rgba(247, 181, 0, 0.7)",
  positive: "rgba(46, 212, 122, 0.5)",
  "very-positive": "rgba(46, 212, 122, 0.7)",
  "fill-bars": "#E0E0E0",
};

export enum AppColors {
  RedLight6 = "",
  MauveLight6 = "",
  AmberLight6 = "#E9C162",
  PinkLight6 = "#EFBFDD",
  GreenLight6 = "",
  RedLight7 = "#f4a9aa",
  MauveLight7 = "#d0cdd7",
  AmberLight7 = "#e9c162",
  PinkLight7 = "#e7acd0",
  GreenLight7 = "#8eceaa",
  RedLight8 = "#eb8e90",
  MauveLight8 = "#bcbac7",
  AmberLight8 = "#e2a336",
  PinkLight8 = "#dd93c2",
  GreenLight8 = "#5bb98b",
  RedLight9 = "#e5484d",
  MauveLight9 = "#8e8c99",
  AmberLight9 = "#ffc53d",
  PinkLight9 = "#d6409f",
  GreenLight9 = "#30a46c",
  RedLight10 = "#dc3e42",
  MauveLight10 = "#84828e",
  AmberLight10 = "#ffba18",
  PinkLight10 = "#cf3897",
  GreenLight10 = "#2b9a66",
  RedLight11 = "#ce2c31",
  MauveLight11 = "#65636d",
  AmberLight11 = "#ab6400",
  PinkLight11 = "#c2298a",
  GreenLight11 = "#218358",
  RedLight12 = "#641723",
  MauveLight12 = "#211f26",
  AmberLight12 = "#4f3422",
  PinkLight12 = "#651249",
  GreenLight12 = "#193b2d",
  primary = "#031447",
  chartBlue = "#062993",
  brandColor = "#8E4EC6",
  brandAccentColor = "#12A594",
  textSubtle = "#65636D",
  otherTopic = "#B5B2BC",
  textBrandSpoknAISubtle = "#8145B5",
  iconTealSubtle = "#008573",
  brandActiveColor = "#8C00F012",
  buttonDefaultBackground = "#e3dfe6",
  whiteText = "#FFFFFF",
  backgroundActiveColor = '#48295C',
  backgroundSpoknAIBrandA = "rgba(140, 0, 240, 0.07)",
};

export const SPEAKER_COLORS = [
  { backgroundColor: "#FEE9F5", color: "#651249" },
  { backgroundColor: "#F2EFF3", color: "#031447" },
  { backgroundColor: "#E6F6EB", color: "#193B2D" },
  { backgroundColor: "#FEFBE9", color: "#4F3422" }
];